<template>
  <component
    :is="is"
    :class="
      cn(
        'rainbow-button',
        'group relative inline-flex cursor-pointer items-center justify-center border-0 bg-[length:200%] font-medium text-primary-foreground transition-colors [background-clip:padding-box,border-box,border-box] [background-origin:border-box] [border:calc(0.08*1rem)_solid_transparent] focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50',
        'before:absolute before:bottom-[-15%] before:left-1/2 before:z-0 before:h-[12%] before:w-1/2 before:-translate-x-1/2 before:bg-[linear-gradient(90deg,var(--color-1),var(--color-5),var(--color-3),var(--color-4),var(--color-2))] before:bg-[length:200%] before:[filter:blur(calc(0.4*1rem))]',
        'bg-[linear-gradient(hsl(var(--primary)),hsl(var(--primary))),linear-gradient(hsl(var(--primary))_50%,rgba(var(--primary),0.6)_80%,rgba(var(--primary),0)),linear-gradient(90deg,var(--color-1),var(--color-5),var(--color-3),var(--color-4),var(--color-2))]',
        'dark:bg-[linear-gradient(hsl(var(--primary)),hsl(var(--primary))),linear-gradient(hsl(var(--primary))_50%,rgba(var(--primary),0.6)_80%,rgba(0,0,0,0)),linear-gradient(90deg,var(--color-1),var(--color-5),var(--color-3),var(--color-4),var(--color-2))]',
        'shadow-xxl',
        circular ? 'w-12 h-12 p-3' : 'h-11 px-8 py-2',
        props.class,
      )
    "
    :style="{
      '--intensity': intensity,
      '--glow-intensity': intensity > 0.7 ? (intensity - 0.7) * 3.33 : 0,
    }"
  >
    <slot />
  </component>
</template>

<script setup lang="ts">
import { cn } from "~/lib/utils";


interface RainbowButtonProps {
  class?: string;
  is?: string;
  speed?: number;
  circular?: boolean;
  intensity?: number;
}

const props = withDefaults(defineProps<RainbowButtonProps>(), {
  speed: 2,
  is: "button",
  circular: false,
  intensity: 0.3,
});

const speedInSeconds = computed(() => `${props.speed}s`);
</script>

<style scoped>
.rainbow-button {
  --color-1: hsla(0 100% 65% / var(--intensity));
  --color-2: hsla(270 100% 72% / var(--intensity));
  --color-3: hsla(210 100% 70% / var(--intensity));
  --color-4: hsla(195 100% 68% / var(--intensity));
  --color-5: hsla(90 100% 65% / var(--intensity));
  --speed: v-bind(speedInSeconds);
  animation: rainbow var(--speed) infinite linear;
  box-shadow: 0 0 calc(var(--glow-intensity) * 20px) calc(var(--glow-intensity) * 5px) hsla(var(--primary) / 0.3);
  transition: box-shadow 0.3s ease;
}

.rainbow-button:before {
  animation: rainbow var(--speed) infinite linear;
  opacity: calc(var(--intensity) * 1.2);
  filter: blur(calc((0.4 + var(--glow-intensity)) * 1rem));
  transition: filter 0.3s ease;
}

@keyframes rainbow {
  0% {
    background-position: 0;
  }
  100% {
    background-position: 200%;
  }
}
</style>