import { ref, computed } from "vue";
import { theme } from "#tailwind-config";
import type { CSSProperties } from "vue";

export function useChatStyles() {
  const colorMode = useColorMode();
  const windowHeight = ref(0); // You might need to set this value properly

  const chatStyle = computed<CSSProperties>(() => ({
    backgroundColor: "transparent",
    borderRadius: "0px",
    width: "435px",
    height: `${windowHeight.value * 0.5}px`,
    border: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    zIndex: "1000",
    position: "relative",
    bottom: "-24px",
  }));

  const inputAreaStyle = ref({
    styles: {
      container: {
        display: "flex",
        width: "495px",
        flexDirection: "row",
        textAlign: "left",
        padding: "0px",
        margin: "0px",
        alignItems: "end",
        justifyContent: "center",
        paddingTop: "6px",
      },
    },
  });

  const textInput = computed(() => ({
    styles: {
      text: {
        backgroundColor: "transparent",
        border: "none",
        color: "hsl(var(--primary))",
      },
      container: {
        width: "350px",
        left: "22px",
        border: `1.5px solid`,
        borderColor: "hsl(var(--muted))",
        borderRadius: "100px",
        height: "40px",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: "10px",
        margin: "0px",
        marginBottom: "4px",
        marginRight: "-10px",
        background: "hsl(var(--muted))",
        boxShadow: "none",
      },
    },
    placeholder: { text: "Type your message.." },
  }));

  const submitButtonStyles = ref({
    
  });

  const auxiliaryStyle = computed(
    () => `
    ::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: hsl(var(--primary));
      border-radius: 5px; 
    }
      
    ::-webkit-scrollbar-track {
      background-color: hsl(var(--background));
      border-radius: 5px;
    }

    #input{
      padding-top: 6px;
    }
    .input-button-svg.inside-right{
      
      margin-right: -20px; 
    }
    


  `
  );

  const avatars = computed(() => ({
    default: {
      styles: {
        avatar: { height: "30px", width: "30px" },
        container: { marginTop: "8px" },
      },
    },
    ai: {
      src:
        colorMode.value == "dark"
          ? "/GameIconsMonsteraLeafWhite.svg"
          : "/GameIconsMonsteraLeafBlack.svg",
      styles: { avatar: { marginLeft: "-3px" } },
    },
    user: {
      src: "", // You'll need to set this dynamically
      styles: { avatar: { display: "none", borderRadius: "15px" } },
    },
  }));

  const messageStyles = computed(() => ({
    loading: {
      bubble: {
        backgroundColor: "hsl(var(--muted))",
        fontSize: "10px",
        color: "hsl(var(--primary))",
      },
    },
    error: {
      bubble: {
        backgroundColor: theme.colors.destructive,
      },
    },
    default: {
      ai: {
        bubble: {
          background: "linear-gradient(45deg, hsl(var(--primary)/50), hsl(var(--muted-foreground)/50))",
          color: "hsl(var(--muted))",
          fontSize: "16px",
          maxWidth: "75%",
        },
      },
      user: {
        bubble: {
          background: "linear-gradient(45deg, hsl(var(--muted)/10), hsl(var(--background)))",
          color: "hsl(var(--primary))",
          fontSize: "16px",
          maxWidth: "75%",
        },
      },
    },
  }));

  return {
    chatStyle,
    inputAreaStyle,
    textInput,
    submitButtonStyles,
    auxiliaryStyle,
    avatars,
    messageStyles,
  };
}
